import React, { useEffect } from "react";
import styled from "styled-components";
import { mobileSize } from "../../../util/variables";
import theme from "../../../util/theme";
import { ThankYou } from "../../../components/PageSegments/subscription/ThankYouV2";
import { BasicLayout } from "../../../layouts/BasicLayout";
import { gift_page_view } from "../../../services/mixpanel/gift-flow-events";

export default () => {
  useEffect(() => {
    gift_page_view("GiftRedeemComplete");
  }, []);

  return (
    <BasicLayout noIndex>
      <FlexContainer>
        <ThankYou />
      </FlexContainer>
    </BasicLayout>
  );
};

const FlexContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  background: ${theme.WHITE_COLOR};

  @media ${mobileSize} {
    justify-content: flex-start;
  }
`;
